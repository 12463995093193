html {
  background-color: #000;
  background-image: linear-gradient(to top right, #007b9a, #222238);
  /* background-image: linear-gradient(to top right, #006da1, #0b0b0c); */
  margin: 0;
  min-height: 100%;
  margin: 0;
}
body {
  color: #000;
  margin: 0;
}
a {
  color: #029ce6;
  transition: .3s;
}
.mainHeader {
  padding: 20px 20px 0;
  max-width: 1600px;
  margin: 0px auto;
  font-family: 'Raleway', sans-serif;
}
.mainHeader nav ul {
  text-align: right;
  padding: 0;
}
.mainHeader nav ul li {
  list-style-type: none;
  list-style-position: outside;
}
.mainHeader nav ul li a {
  display: block;
  padding: 10px;
  color: #f33a3a;
  font-weight: bold;
  letter-spacing: 3px;
  font-size: 18px;
  font-family: 'Space Mono', monospace;
  transition: .5s;
  text-decoration: none;
}
.mainHeader nav ul li a:hover, .mainHeader nav ul li a.active {
  color: #fff;
}
@media screen and (min-width: 600px) {
  .mainHeader nav ul li {
    display: inline-block;
    margin: 0 20px;
  }
}

.mainContent {
  padding: 30px 20px;
  line-height: 1.9;
  font-size: 18px;
  color: #e3e3e3;
  font-family: 'Raleway', sans-serif;
  max-width: 1100px;
  box-sizing: border-box;
  margin: 0px auto;
}
.mainContent h1 {
  margin: 0;
  padding: 0 0 25px 0;
  line-height: 1.2;
  font-size: 38px;

  color: #029ce6;
}
.mainContent h2 {
  margin: 0;
  padding: 0 0 20px 0;
  line-height: 1.1;
  font-size: 24px;
  color: #029ce6;
}
.mainContent h3 {
  margin: 0;
  padding: 0 0 15px 0;
  line-height: 1.1;
  font-weight: bold;
  font-size: 22px;
  color: #000;
}
.mainContent a {
  color: #029ce6;
  transition: .5s;
}
.mainContent a:hover {
  text-decoration: none;
  color: #fff;
}
.mainContent p {
  padding: 0 0 30px 0;
  margin: 0;
}
.mainContent ul {
  margin: 0;
  padding: 0 0 20px 30px;
}
.mainContent .contentInnerBox ul {
  font-size: 15px;
}
.mainContent ul.secBlock1 {
  padding: 0 0 0 30px;
}
.mainContent ul li {
  word-wrap: break-word;
}
ul.mcNewList li {
  color: #000;
}
ul.mcNewList li a {
  color: #000;
}
.mcHIImage {
  display: block;
  max-width: 215px;
  margin: 0 30px 25px 0;
  width: 100%;
  height: auto;
  filter: drop-shadow(8px 8px 10px rgba(0, 0, 0, .4));
}
.mcHIImage2 {
  display: block;
  max-width: 250px;
  margin: 0 0 25px 0;
  width: 100%;
  height: auto;
  filter: drop-shadow(8px 8px 10px rgba(0, 0, 0, .4));
}
.mcHIImage3 {
  display: block;
  max-width: 250px;
  margin: 0 0 25px 0;
  width: 100%;
  height: auto;
  filter: drop-shadow(8px 8px 10px rgba(0, 0, 0, .4));
}
.mcHIImage4 {
  display: block;
  max-width: 250px;
  margin: 0 0 25px 0;
  width: 100%;
  height: auto;
  filter: drop-shadow(8px 8px 10px rgba(0, 0, 0, .4));
}
.clearBoth {
  clear: both;
}
/* @media screen and (min-width: 450px) {
  .mcHIImage {
    float: left;
    margin: 0 20px 5px 0;
    width: 50%;
  }
} */
@media screen and (min-width: 500px) {
  .mcHIImage {
    float: left;
    margin: 0 30px 10px 0;
  }
  .mcHIImage2 {
    float: right;
    margin: 0 0 10px 30px;
    max-width: 280px;
  }
  .mcHIImage3 {
    float: left;
    margin: 0 30px 10px 0;
    max-width: 280px;
  }
  .mcHIImage4 {
    float: right;
    margin: 0 0 10px 30px;
    max-width: 280px;
  }
}
@media screen and (min-width: 600px) {
  .contentInnerBox {
    margin: 0 30px 0 0;
  }
  .contentInnerBox:nth-child(even) {margin: 0;}

  .mainContent ul.secBlock1 {
    display: inline-block;
    vertical-align: top;
    margin: 0 20px 0 0;
    padding: 0 0 30px 30px;
  }
  .cIBoxOuter .mainContent ul.secBlock1 {
    display: inline-block;
    vertical-align: top;
    margin: 0 20px 0 0;
    padding: 0 0 30px 30px;
  }
  .mainContent ul.secBlock2 {
    display: inline-block;
    vertical-align: top;
    width: calc(100%/2 - 50px);
    padding: 0 0 30px 30px;
  }

}
@media screen and (min-width: 800px) {
  .mainContent {
    padding: 30px 40px;
  }
  .mainContent h1 {
    font-size: 40px;
  }
}
@media screen and (min-width: 1000px) {
  .mainContent ul.secBlock1, .mainContent ul.secBlock2 {
    max-width: 280px;
  }
  .cIBoxOuter .contentInnerBox {
    display: inline-block;
    vertical-align: top;
  }
}
@media screen and (min-width: 1120px) {
  .mainContent {
    padding: 30px 60px;
  }
  .mainContent h1 {
    font-size: 44px;
  }
  .mainContent h2 {
    font-size: 25px;
  }
  .mainContent h3 {
    font-size: 20px;
  }
  .mainContent ul.secBlock1, .mainContent ul.secBlock2 {
    max-width: 335px;
  }
}


.mainFooter {
  padding: 20px 20px;
  clear: both;
}
.mainFooter p {
  font-size: 13px;
  text-align: center;
  color: #e3e3e3;
}